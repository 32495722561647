import button_img from "../../assets/elements/BotaoVazio.png";
// import playAudio from "../../utils/playAudio";
import style from "./button.module.css";
import cx from "classnames";

type Props = {
  label: string;
  onClick?: () => void;
  className?: string;
  disabled?: boolean;
};

const play = (id: string) => {
  const audio = document.getElementById(id) as any;

  if (audio) {
    if (!audio.paused) {
      audio.pause();
      audio.currentTime = 0;
    }

    audio.play();
  }
};

const Button = ({ label, onClick, className, disabled }: Props) => {
  const handleClick = () => {
    // playAudio("./sound/Botao.mp3");
    play("Botao");
    onClick?.();
  };

  return (
    <button
      type="button"
      onClick={handleClick}
      className={cx(style.button, className)}
      disabled={disabled}
    >
      <span className={style.label}>{label}</span>
      <img alt="" src={button_img} className={style.image} />
    </button>
  );
};

export default Button;
