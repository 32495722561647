import formatNumber from "./formatNumber";

const getFormattedTime = (totalSeconds: number): string => {
  const minutes = Math.floor(totalSeconds / 60);
  const seconds = Math.floor(totalSeconds % 60);

  return `${formatNumber(minutes, 2)}:${formatNumber(seconds, 2)}`;
};

export default getFormattedTime;
