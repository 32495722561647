import classNames from "classnames";
import style from "./counter-particle.module.css";

type Props = {
  value: number;
  className?: string;
};

const CounterParticle = ({ value = 0, className }: Props) => (
  <span className={classNames(style.counter, className)}>{value}</span>
);

export default CounterParticle;
