import { useEffect, useState } from "react";
import cx from "classnames";

import speakerOn from "../../assets/elements/speaker-24.svg";
import speakerOff from "../../assets/elements/speaker-off-24.svg";

import style from "./sound-control.module.css";

type Props = {
  className?: string;
};

const SoundControl = ({ className }: Props) => {
  const [soundEnabled, setSoundEnabled] = useState<boolean>(true);

  const toggle = () => {
    inc_event("Botões", "Mutar");
    setSoundEnabled((x) => !x);
  };

  useEffect(() => {
    document.querySelectorAll("audio").forEach((audio) => {
      audio.muted = !soundEnabled;
    });
  }, [soundEnabled]);

  return (
    <button onClick={toggle} className={cx(style.button, className)}>
      {soundEnabled ? (
        <img src={speakerOn} alt="" />
      ) : (
        <img src={speakerOff} alt="" />
      )}
    </button>
  );
};

export default SoundControl;
