import type { Treasure } from "../App";
import chocolate_img from "../assets/elements/biscoitos/chocolate.png";
import chocchoc_img from "../assets/elements/biscoitos/chocchoc.png";
import morango_img from "../assets/elements/biscoitos/morango.png";
import baunilha_img from "../assets/elements/biscoitos/baunilha.png";

const imgByType: { [key in Treasure]: string } = {
  chocolate: chocolate_img,
  choc_choc: chocchoc_img,
  morango: morango_img,
  baunilha: baunilha_img,
};

const itemFoundAnimation = (x: number, y: number, type: Treasure): void => {
  const element = document.createElement("img");

  element.src = imgByType[type];
  element.classList.add("element-found-animated");

  if (type === "baunilha") {
    element.classList.add("big");
  }

  element.style.right = `${window.screen.width - x}px`;
  element.style.top = `${y}px`;

  document.getElementById("root")?.appendChild(element);

  setTimeout(() => {
    element.remove();

    const pyro = document.createElement("pyro");
    pyro.classList.add("pyro");

    const before = document.createElement("before");
    before.classList.add("before");
    const after = document.createElement("after");
    after.classList.add("after");
    pyro.appendChild(before);
    pyro.appendChild(after);

    pyro.style.right = "18svh";
    pyro.style.top = "10svh";
    pyro.style.left = "unset";
    pyro.style.zIndex = "9";

    document.getElementById("root")?.appendChild(pyro);

    setTimeout(() => {
      pyro.remove();
    }, 5000);
  }, 1000);
};

export default itemFoundAnimation;
