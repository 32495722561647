import { useCallback, useEffect, useMemo, useRef, useState } from "react";
// import background from "./assets/elements/final/Referencia.png";
// import background from "./assets/elements/min/bg.png";
import bg1 from "./assets/elements/min/bg-splitted/Fundo1.png";
import bg2 from "./assets/elements/min/bg-splitted/Fundo2.png";
import bg3 from "./assets/elements/min/bg-splitted/Fundo3.png";
import bg4 from "./assets/elements/min/bg-splitted/Fundo4.png";
import bg5 from "./assets/elements/min/bg-splitted/Fundo5.png";
import bg6 from "./assets/elements/min/bg-splitted/Fundo6.png";
import chocolate_img from "./assets/elements/biscoitos/chocolate.png";
import chocchoc_img from "./assets/elements/biscoitos/chocchoc.png";
import morango_img from "./assets/elements/biscoitos/morango.png";
import baunilha_img from "./assets/elements/biscoitos/baunilha.png";

// import background from "./assets/references/Referencia_patas.jpg";
// import background from "./assets/references/Ref imagem completa/ref.jpg";
import IterableElement, {
  InteractionType,
} from "./components/iterable-element";
import Scene, { SceneRefObject } from "./components/scene";
import cx from "classnames";

import arbusto_1_img from "./assets/elements/min/Elemento3.png";
import bola_img from "./assets/elements/min/Elemento10.png";
import castelo_img from "./assets/elements/min/Elemento7.png";
import balde_1_img from "./assets/elements/min/Elemento11.png";
import arbusto_2_img from "./assets/elements/min/Elemento5.png";
import cesto_1_img from "./assets/elements/min/Elemento6.png";
import mochila_1_img from "./assets/elements/min/Elemento14.png";
import chapeu_img from "./assets/elements/min/Elemento13.png";
import sanduiche_img from "./assets/elements/min/Elemento17.png";
import mochila_2_img from "./assets/elements/min/Elemento15.png";
import cesto_2_img from "./assets/elements/min/Elemento9.png";
import arbusto_3_img from "./assets/elements/min/Elemento4.png";
import balde_2_img from "./assets/elements/min/Elemento12.png";
import boia_img from "./assets/elements/min/Elemento16.png";
import arbusto_4_img from "./assets/elements/min/Elemento1.png";
import cesto_3_img from "./assets/elements/final/Ellemento21.png";
import bola_2_img from "./assets/elements/final/Elemento18.png";
import borboleta_img from "./assets/elements/final/Ellemento20.png";
import urso_img from "./assets/elements/final/Elemento19.png";
import cao_img from "./assets/elements/final/Elemento21.png";
import gato_img from "./assets/elements/final/Elemento22.png";

import instagram_img from "./assets/elements/intagram.png";
import wpp_img from "./assets/elements/wpp.png";

// import caca_ao_tesouro_img from "./assets/elements/CacaAoTesouro.png";
// import caca_ao_tesouro_img from "./assets/elements/logo_coelho.png";
import caca_ao_tesouro_img from "./assets/Logo.png";
import timer_img from "./assets/elements/Timer.png";
import chocker_img from "./assets/elements/pontos.png";
// import biscoito_img from "./assets/Biscoito.png";
import Button from "./components/button";
// import takeScreenshot from "./utils/takeScreenshot";
import isMobileOrTablet from "./utils/isMobileOrTablet";
// import waitForSeconds from "./utils/waitForSeconds";
import getFormattedTime from "./utils/getFormattedTime";
import SoundControl from "./components/sound-control";
import Pyro from "./components/pyro";
import CounterParticle from "./components/counter-particle";
import classNames from "classnames";
import takeScreenshot from "./utils/takeScreenshot";

const time = 3 * 60 * 1000;
// const time = Infinity;

const hurryUpSeconds = 30;

const isMobile = isMobileOrTablet();

export type Treasure = "morango" | "baunilha" | "chocolate" | "choc_choc";

const TREASURE_TYPES: Treasure[] = [
  "morango",
  "baunilha",
  "chocolate",
  "choc_choc",
];

const TREASURES_COUNT = 7;

type IterableId =
  | "arbusto_1"
  | "bola"
  | "castelo"
  | "balde_1"
  | "arbusto_2"
  | "cesto_1"
  | "mochila_1"
  | "chapeu"
  | "sanduiche"
  | "mochila_2"
  | "cesto_2"
  | "arbusto_3"
  | "balde_2"
  | "boia"
  | "arbusto_4"
  | "cesto_3"
  | "bola_2";

type IterableElementProps = {
  image: string;
  type?: InteractionType;
  analytics: string;
  swapImage?: {
    image: string;
    id: string;
  };
  hasTreasure?: Treasure;
};

const play = (id: string) => {
  const audio = document.getElementById(id) as any;

  if (audio) {
    if (!audio.paused) {
      audio.pause();
      audio.currentTime = 0;
    }

    audio.play();
  }
};

const stop = (id: string) => {
  const audio = document.getElementById(id) as any;

  if (audio) {
    audio.pause();
    audio.currentTime = 0;
  }
};

function App() {
  const [state, setState] = useState<"start" | "instructions" | "game" | "end">(
    "start"
  );
  const [isOpen, setIsOpen] = useState<
    Partial<{ [id in IterableId]: boolean }>
  >({});
  const [hideStart, setHideStart] = useState<boolean>(false);
  const [remainingTime, setRemainingTime] = useState<number>(time);
  const timerRef = useRef<ReturnType<typeof setInterval>>();
  const [treasureFound, setTreasureFound] = useState<
    Partial<{ [id in IterableId]: boolean }>
  >({});
  // const [log, setLog] = useState<string[]>([]);
  const [screenshotData, setScreenshotData] = useState<string | null>(null);
  const filesArray = useRef<File[]>([]);
  const [showingResults, setShowingResults] = useState<boolean>(true);
  // const [savingScreenshot, setSavingScreenshot] = useState<boolean>(false);
  const sceneRef = useRef<SceneRefObject>(null);
  const iterableElements = useRef<{ [id in IterableId]: IterableElementProps }>(
    {
      arbusto_1: {
        image: arbusto_1_img,
        type: "hide",
        analytics: "Arbusto 1",
      },
      bola: {
        image: bola_img,
        type: "hide",
        analytics: "Bola",
      },
      castelo: {
        image: castelo_img,
        type: "hide",
        analytics: "Castelo",
      },
      balde_1: {
        image: balde_1_img,
        type: "hide",
        analytics: "Balde",
      },
      arbusto_2: {
        image: arbusto_2_img,
        type: "hide",
        analytics: "Arbusto 2",
      },
      cesto_1: {
        image: cesto_1_img,
        type: "hide",
        analytics: "Cesto",
      },
      mochila_1: {
        image: mochila_1_img,
        type: "hide",
        analytics: "Mochila",
      },
      chapeu: {
        image: chapeu_img,
        type: "hide",
        analytics: "Chapéu",
      },
      sanduiche: {
        image: sanduiche_img,
        type: "hide",
        analytics: "Sanduíche",
      },
      mochila_2: {
        image: mochila_2_img,
        type: "hide",
        analytics: "Mochila 2",
      },
      cesto_2: {
        image: cesto_2_img,
        type: "hide",
        analytics: "Cesto",
      },
      arbusto_3: {
        image: arbusto_3_img,
        type: "hide",
        analytics: "Arbusto 3",
      },
      balde_2: {
        image: balde_2_img,
        type: "hide",
        analytics: "Balde 2",
      },
      boia: {
        image: boia_img,
        type: "hide",
        analytics: "Boia",
      },
      arbusto_4: {
        image: arbusto_4_img,
        type: "hide",
        analytics: "Arbusto 4",
      },
      cesto_3: {
        image: cesto_3_img,
        type: "hide",
        analytics: "Cesto 3",
      },
      bola_2: {
        image: bola_2_img,
        type: "hide",
        analytics: "Bola 2",
      },
    }
  );

  const generateElementsWithTreasure = () => {
    const treasureIds = Object.keys(iterableElements.current).sort(
      () => Math.random() - 0.5
    ) as IterableId[];

    for (let i = 0; i < treasureIds.length; i++) {
      iterableElements.current[treasureIds[i]].hasTreasure =
        i < TREASURES_COUNT
          ? TREASURE_TYPES[Math.floor(Math.random() * TREASURE_TYPES.length)]
          : undefined;

      // if (i < TREASURES_COUNT) {
      //   console.log(treasureIds[i]);
      // }
    }
  };
  // const soundtrackRef = useRef<AudioBufferSourceNode | null>();

  const startSoundtrack = async () => {
    // soundtrackRef.current = await playAudio("./sound/TrilhaSonora.mp3", true);
    // play("./sound/TrilhaSonora.mp3", true);
    play("TrilhaSonora");
  };

  const startGame = () => {
    generateElementsWithTreasure();
    setState("game");
    setRemainingTime(time);
    setTreasureFound({});
    setIsOpen({});
    startSoundtrack();
    sceneRef.current?.scrollBack();
    setShowingResults(true);

    setTimeout(() => {
      setHideStart(true);
    }, 300);
    timerRef.current = setInterval(() => {
      setRemainingTime((value) => value - 1000);
    }, 1000);
  };

  const totalFound = useMemo(
    () =>
      Object.values(treasureFound).reduce(
        (sum, value) => sum + (value ? 1 : 0),
        0
      ),
    [treasureFound]
  );

  const victory = useMemo(() => totalFound >= TREASURES_COUNT, [totalFound]);

  // const shouldBeClosed = (id: IterableId, found: typeof treasureFound) => {
  //   if (
  //     (id === "cozinha_gaveta_baixo" || id === "cozinha_gaveta_cima") &&
  //     Boolean(found["cozinha_gaveta_meio"])
  //   ) {
  //     return true;
  //   }

  //   return false;
  // };

  // const pushLog = useCallback(
  //   (message: string) => {
  //     setLog((log) => [...log, message]);
  //   },
  //   [setLog]
  // );

  const saveScreenshot = useCallback(async () => {
    setShowingResults(true);
    // setSavingScreenshot(true);
    // pushLog("share started");
    // await waitForSeconds(0.3);

    setTimeout(() => {
      setShowingResults(false);
    }, 4000);

    const screenshot = await takeScreenshot();

    if (isMobile) {
      // pushLog("screenshot taken");
      const blob = await (await fetch(screenshot)).blob();
      // pushLog("blob created");
      const files: File[] = [
        new File([blob], "screenshot.png", {
          type: blob.type,
          lastModified: new Date().getTime(),
        }),
      ];
      filesArray.current = files;
    } else {
      setScreenshotData(screenshot);
    }

    // setSavingScreenshot(false);
    // }, [pushLog, setShowingResults]);
  }, [setShowingResults]);

  useEffect(() => {
    if (victory) {
      inc_event("Finalização", "Encontrou todos");
      setTimeout(() => {
        setState("end");
        // playAudio("./sound/FimJogo.mp3");
        play("FimJogo");
        // soundtrackRef.current?.stop();
        stop("TrilhaSonora");
      }, 2000);
    }
  }, [treasureFound, victory]);

  useEffect(() => {
    if (Math.floor(remainingTime / 1000) === hurryUpSeconds) {
      // playAudio("./sound/TempoAcabando.mp3");
      play("TempoAcabando");
    }

    if (remainingTime <= 0 && state === "game") {
      inc_event("Finalização", "Não encontrou todos");
      setState("end");
      // playAudio("./sound/TenteNovamente.mp3");
      play("TenteNovamente");
      // soundtrackRef.current?.stop();
      stop("TrilhaSonora");
    }
  }, [remainingTime, setState, state]);

  useEffect(() => {
    if (state === "end") {
      saveScreenshot();
    }
  }, [state, saveScreenshot]);

  useEffect(() => {
    if (state === "end" && timerRef.current) {
      clearInterval(timerRef.current);
    }
  }, [state, timerRef]);

  // eslint-disable-next-line
  function onShare() {
    if (isMobile) {
      const shareData = {
        files: filesArray.current,
      };

      // pushLog("navigator.share called");
      try {
        navigator.share(shareData);
      } catch (error) {
        // alert(`Share error: ${error}`);
      }
    } else {
      const link = document.createElement("a");
      link.href = screenshotData || "";
      link.download = "screenshot.png";
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    }
  }

  function onShareLink() {
    const message = "Encontre os biscoitos Picnic";
    try {
      if (isMobile) {
        navigator.share({
          title: "Caca ao Picnic",
          text: message,
          url: window.location.href,
        });
      } else {
        const link = document.createElement("a");
        const params = new URLSearchParams({
          text: `${message}! Jogue também: ${window.location.href}`,
        });
        link.href = `https://wa.me/?${params.toString()}`;
        link.target = "_blank";
        document.body.appendChild(link);
        console.log(link);
        link.click();
        document.body.removeChild(link);
      }
    } catch (error) {
      // alert(`Share error: ${error}`);
    }
  }

  return (
    <div className="App">
      <SoundControl className="soundButton" />
      {/* <span id="log">{log.join("\n")}</span> */}
      <div
        id="start_popover"
        className={cx("popover", {
          noBackground: !(state === "start" || state === "instructions"),
          hidden: hideStart,
        })}
      >
        <div id="start" className={cx(state !== "start" && "hidden")}>
          <div className="logo_container">
            <div>
              <img alt="" src={caca_ao_tesouro_img} className="cacaAoTesouro" />
              <img
                alt=""
                src={chocolate_img}
                id="chocolate"
                className="biscoito_start"
              />
              <img
                alt=""
                src={chocchoc_img}
                id="chocchoc"
                className="biscoito_start"
              />
              <img
                alt=""
                src={morango_img}
                id="morango"
                className="biscoito_start"
              />
              <img
                alt=""
                src={baunilha_img}
                id="baunilha"
                className="biscoito_start"
              />
            </div>
          </div>
          <Button
            label="jogar"
            className="button"
            onClick={() => {
              inc_event("Botões", "Jogar");
              setState("instructions");
            }}
          />
          {/* <div className="logo animated" /> */}
        </div>
        <div
          id="instructions"
          className={cx({
            idle: state === "start",
            hidden: state !== "start" && state !== "instructions",
          })}
        >
          <img alt="" src={caca_ao_tesouro_img} className="cacaAoTesouro" />
          <span className="instructions-text">
            Nossos amiguinhos querem fazer um PicNic
            <br />
            no parque. Vamos ajudá-los a encontrar
            <br />
            os biscoitos escondidos?
            <br />
            <br />
            1 - Clique nos elementos do cenário para
            <br />
            descobrir onde estão os biscoitos PicNic;
            <br />
            <br />
            2 - Arraste a tela para percorrer
            <br />
            por todo o cenário;
            <br />
            <br />
            3 - Quem encontrar os 7 biscoitos
            <br />
            em um menor tempo é o vencedor!
          </span>
          <Button
            label="começar"
            className="button"
            onClick={() => {
              inc_event("Botões", "Começar");
              startGame();
            }}
          />
          {/* <div className="logo animated" /> */}
        </div>
      </div>
      <div
        id="end"
        className={cx("popover", {
          hidden: state !== "end",
        })}
      >
        <div
          id="screenshot"
          className={cx({
            hidden: !victory || !showingResults,
          })}
        >
          <img alt="" src={caca_ao_tesouro_img} className="cacaAoTesouro" />
          <span className="end-text">
            É muito divertido brincar de PicNic!
            <br />
            Desafie seus amigos!
            <br />
            <br />
            Eu encontrei todos os biscoitos PicNic em
            <br />
            <strong>{getFormattedTime((time - remainingTime) / 1000)}</strong>
            <br />
            Agora é a sua vez!
          </span>
          <div className="instagram">
            <img alt="" src={instagram_img} />
            <span>
              Acesse @vilmaalimentos para
              <br />
              jogar também (link na Bio)
            </span>
          </div>
          <div className="logo" />
        </div>
        <div
          id="end_inner"
          className={cx({
            idle: !(!victory || !showingResults),
          })}
        >
          <img alt="" src={caca_ao_tesouro_img} className="cacaAoTesouro" />
          <span className={classNames("end-text", victory && "disappear")}>
            Poxa, não foi dessa vez!
            <br />
            Mas não se preocupe, é só tentar de novo.
          </span>
          <span className={classNames("end-text", !victory && "disappear")}>
            Convide seus amigos e vamos brincar de PicNic!
          </span>
          <div className={classNames("social-btns", !victory && "disappear")}>
            <button
              className="social-btn"
              onClick={() => {
                inc_event("Botões", "Compartilhar");
                onShare();
              }}
            >
              <img src={instagram_img} alt="instagram" />
            </button>
            <button
              className="social-btn"
              onClick={() => {
                inc_event("Botões", "Compartilhar");
                onShareLink();
              }}
            >
              <img src={wpp_img} alt="whatsapp" />
            </button>
          </div>

          <Button
            label="jogar novamente"
            className={classNames("button", !victory && "disappear")}
            onClick={() => {
              inc_event("Botões", "Jogar novamente");
              startGame();
            }}
          />
          <Button
            label="tentar de novo"
            className={classNames("button", victory && "disappear")}
            onClick={startGame}
          />
          <div className="logo animated" />
        </div>
      </div>
      <div className={cx("hud", state !== "game" && "hidden")} id="timer">
        <img alt="" src={timer_img} />
        <span>{getFormattedTime(remainingTime / 1000)}</span>
      </div>
      <div className={cx("hud", state !== "game" && "hidden")} id="choker">
        <img alt="" src={chocker_img} />
        <span>{TREASURES_COUNT - totalFound}</span>
      </div>
      <Scene disabled={state !== "game"} ref={sceneRef}>
        {/* <img src={background} alt="" id="background" /> */}
        <div id="background">
          <img src={bg1} alt="" />
          <img src={bg2} alt="" />
          <img src={bg3} alt="" />
          <img src={bg4} alt="" />
          <img src={bg5} alt="" />
          <img src={bg6} alt="" />
        </div>
        {Object.entries(iterableElements.current).map(
          ([id, { image, type, swapImage, hasTreasure, analytics }]) => (
            <IterableElement
              key={id}
              id={id}
              type={type}
              hasTreasure={
                !treasureFound[id as IterableId] ? hasTreasure : undefined
              }
              isOpen={
                // !shouldBeClosed(id as IterableId, treasureFound) &&
                isOpen[id as IterableId]
              }
              onClick={(id) => {
                inc_event("Objetos", analytics);
                setIsOpen({ [id]: !isOpen[id as IterableId] });
                if (hasTreasure) {
                  if (!treasureFound[id as IterableId]) {
                    // playAudio("./sound/EncontrouChocker.mp3");
                    play("EncontrouChocker");
                  }

                  setTreasureFound((values) => ({
                    ...values,
                    [id]: true,
                  }));
                } else {
                  // playAudio("./sound/NaoEncontrouChocker.mp3");
                  play("NaoEncontrouChocker");
                }
              }}
            >
              <img alt="" src={image} />
              {swapImage && (
                <img
                  alt=""
                  id={swapImage.id}
                  src={swapImage.image}
                  className="swapImage"
                />
              )}
              {hasTreasure && treasureFound[id as IterableId] && (
                <>
                  <Pyro />
                  <CounterParticle
                    value={totalFound}
                    className={classNames(totalFound === 7 && "disappear")}
                  />
                </>
              )}
            </IterableElement>
          )
        )}
        <img
          className="absolute-img"
          id="borboleta"
          src={borboleta_img}
          alt=""
        />
        <img className="absolute-img" id="urso" src={urso_img} alt="" />
        <img className="absolute-img" id="cao" src={cao_img} alt="" />
        <img className="absolute-img" id="gato" src={gato_img} alt="" />
      </Scene>
      <audio id="Botao" src="sound/Botao.mp3"></audio>
      <audio id="EncontrouChocker" src="sound/EncontrouChocker.mp3"></audio>
      <audio id="FimJogo" src="sound/FimJogo.mp3"></audio>
      <audio
        id="NaoEncontrouChocker"
        src="sound/NaoEncontrouChocker.mp3"
      ></audio>
      <audio id="TempoAcabando" src="sound/TempoAcabando.mp3"></audio>
      <audio id="TenteNovamente" src="sound/TenteNovamente.mp3"></audio>
      <audio id="TrilhaSonora" src="sound/trilha.mp3" loop></audio>
    </div>
  );
}

export default App;
