const formatNumber = (num: number, minSize = 0) => {
  let numStr = num.toString();

  while (numStr.length < minSize) {
    numStr = "0" + numStr;
  }

  return numStr;
};

export default formatNumber;
