import html2canvas from "html2canvas";

const takeScreenshot = () =>
  html2canvas(document.body, {
    allowTaint: true,
    useCORS: true,
  }).then(function (canvas) {
    return canvas.toDataURL("image/png");
  });

// const takeScreenshot = (): Promise<Blob | null> => new Promise((resolve) => {
//   html2canvas(document.body).then(function (canvas) {
//     return canvas.toBlob((blob) => {
//       resolve(blob);
//     });
//   });
// })

export default takeScreenshot;
