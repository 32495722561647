import cx from "classnames";
import "./pyro.scss";

type Props = {
  className?: string;
};

const Pyro = ({ className }: Props) => (
  <div className={cx("pyro", className)}>
    <div className={"before"} />
    <div className={"after"} />
  </div>
);

export default Pyro;
