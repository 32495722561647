import {
  PropsWithChildren,
  forwardRef,
  useCallback,
  useEffect,
  useImperativeHandle,
  useRef,
} from "react";
import cx from "classnames";
import style from "./scene.module.css";
import isMobileOrTablet from "../../utils/isMobileOrTablet";

export type SceneRefObject = {
  scrollBack: () => void;
};

type Props = PropsWithChildren<{
  disabled?: boolean;
  className?: string;
}>;

const isDesktop = !isMobileOrTablet();

const Scene = forwardRef<SceneRefObject, Props>(
  ({ className, children, disabled = false }, ref) => {
    const mouseDown = useRef<boolean>(false);
    const startX = useRef<number>(0);
    const scrollLeft = useRef<number>(0);
    const parentRef = useRef<HTMLDivElement>(null);

    const parentElement = parentRef.current;

    const startDragging = useCallback(
      (e: any) => {
        mouseDown.current = true;
        startX.current = e.pageX - parentElement!.offsetLeft;
        scrollLeft.current = parentElement!.scrollLeft;
      },
      [parentElement]
    );

    const stopDragging = (e: any) => {
      mouseDown.current = false;
    };

    const move = useCallback(
      (e: any) => {
        e.preventDefault();
        if (!mouseDown.current) {
          return;
        }
        const x = e.pageX - parentElement!.offsetLeft;
        const scroll = x - startX.current;
        parentElement!.scrollLeft = scrollLeft.current - scroll;
      },
      [parentElement]
    );

    useEffect(() => {
      if (isDesktop && parentElement) {
        parentElement.addEventListener("mousedown", startDragging);
        parentElement.addEventListener("mouseup", stopDragging);
        parentElement.addEventListener("mouseleave", stopDragging);
        parentElement.addEventListener("mousemove", move);

        return () => {
          if (parentElement) {
            parentElement.removeEventListener("mousedown", startDragging);
            parentElement.removeEventListener("mouseup", stopDragging);
            parentElement.removeEventListener("mouseleave", stopDragging);
            parentElement.removeEventListener("mousemove", move);
          }
        };
      }
    }, [parentElement, move, startDragging]);

    const scrollBack = useCallback(() => {
      parentElement?.scrollTo({
        left: 0,
        behavior: "smooth",
      });
    }, [parentElement]);

    useImperativeHandle(
      ref,
      () => ({
        scrollBack,
      }),
      [scrollBack]
    );

    return (
      <div
        className={cx(style.scene, disabled && style.sceneDisabled, className)}
        ref={parentRef}
      >
        {children}
      </div>
    );
  }
);

export default Scene;
